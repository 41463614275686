module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://yf01q8.oscarfdz.com/graphql","verbose":true,"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"debug":{"graphql":{"writeQueriesToDisk":true,"showQueryVarsOnError":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"html":{"fallbackImageMaxWidth":800,"useGatsbyImage":true,"imageMaxWidth":null,"imageQuality":90,"createStaticFiles":true},"excludeFieldNames":["blocksJSON","saveContent"],"type":{"Post":{"limit":null},"CoreParagraphBlockAttributesV2":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Oscar Fernandez Portfolio","short_name":"GatsbyJS & WP","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"C:\\Users\\kedas\\Desktop\\Final website\\portfolio-gatsby/src/images/oscar-fernandez-full-stack-seo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"90ec37a09ee758804b8f78441ce6c554"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
